p {
  font-size: small;
}
.td {
  padding: 0px;
}
.productsHead * {
  --icon-button-ghost-padding: 0px;
  border-bottom: none;
  padding: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.productsTable * {
  padding-top: 1.5px;
  padding-bottom: 1.5px;
}
