.container {
  border: 1px solid #ddd7d7;
  border-radius: 16px;
  background-color: white;
  width: 500px;
}
.header {
  padding: 16px;
  width: 100%;
}
.footer1 {
  background-color: #f0f2f5;
  padding: 10px;
  width: 100%;
}
.footer2 {
  border-top: 1px solid #ddd7d7;
  justify-content: space-around !important;
  padding: 8px;
}
.button {
  border-radius: 6px;
  padding: 8px 16px;
  background-color: #0766ff;
  color: white;
  font-weight: 500;
  font-size: 16px;
  border: none;
  height: fit-content;
}
.icon {
  border-radius: 50%;
  background-color: #d9d9d9;
  width: 36px;
  height: 36px;
}
.company_name {
  font-size: 16px;
}
.sponsored {
  font-size: 13px;
  color: #888282;
}
.interactive_button_text {
  color: #656565;
}
.primary_text {
  font-weight: 500;
  font-size: 16px;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
