p {
  font-size: small;
}
.td {
  padding: 0px;
}
.productsTable * {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
}
