.content {
  min-width: 97vw;
  height: calc(100vh - var(--space-6));
  background-color: #EDEBF0;
  padding: 0;
  border-radius: 24px 24px 0 0;
  position: fixed;
  top: var(--space-6);
  left: 1.5vw;
}
.previews_container {
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  > :first-child {
    margin-top: 44px;
  }
  > :last-child {
    margin-bottom: 44px;
  }
  > * {
    flex-shrink: 0;
  }
}

.header {
  background: white;
  width: 100%;
  border: 1px solid var(--border);
  padding: 4px 24px;
  height: 76px;
}

.inner_content {
  max-height: calc(100% - 76px);
}
