@import "@radix-ui/themes/styles.css";
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,200..800;1,200..800&display=swap");

* {
  font-family: "Inter", sans-serif;
}

/* override cursor: pointer  for radix defaults */
.radix-themes {
  --default-font-family: "Inter", sans-serif;
  --heading-font-family: "Plus Jakarta Sans", sans-serif;
  --font-weight-medium: 500;

  --cursor-button: pointer;
  --cursor-checkbox: pointer;
  --cursor-disabled: default;
  --cursor-link: pointer;
  --cursor-menu-item: pointer;
  --cursor-radio: pointer;
  --cursor-slider-thumb: grab;
  --cursor-slider-thumb-active: grabbing;
  --cursor-switch: pointer;

  --focus-8: #8b54ff;
}

/* 
  Generated with Radix Custom Palette Generator
  https://www.radix-ui.com/colors/custom
*/
:root,
.light,
.light-theme {
  --violet-1: #f5f5f9;
  --violet-2: #eeeffc;
  --violet-3: #e6e7ff;
  --violet-4: #d9dbff;
  --violet-5: #cdcfff;
  --violet-6: #bdc0ff;
  --violet-7: #a9a9ff;
  --violet-8: #8f88ff;
  --violet-9: #2f048a;
  --violet-10: #3e26a2;
  --violet-11: #5438d2;
  --violet-12: #2e0289;

  --violet-a1: #f0f4ff8a;
  --violet-a2: #eaedffc5;
  --violet-a3: rgb(47, 4, 138, 0.07);
  --violet-a4: #d9dbff;
  --violet-a5: #cdcfff;
  --violet-a6: #bdc0ff;
  --violet-a7: #a9a9ff;
  --violet-a8: #8f88ff;
  --violet-a9: #2c0088fb;
  --violet-a10: #1b0094d8;
  --violet-a11: #2f048a;
  --violet-a12: #2c0088fd;

  --violet-contrast: #fff;
  --violet-surface: #ebeeffcc;
  --violet-indicator: #2f048a;
  --violet-track: #2f048a;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {

    :root,
    .light,
    .light-theme {
      --violet-1: oklch(97.1% 0.0048 282.5);
      --violet-2: oklch(95.6% 0.0173 282.5);
      --violet-3: oklch(93.5% 0.0329 282.5);
      --violet-4: oklch(90.7% 0.0664 282.5);
      --violet-5: oklch(87.4% 0.0989 282.5);
      --violet-6: oklch(83.3% 0.1304 282.5);
      --violet-7: oklch(77.5% 0.1655 282.5);
      --violet-8: oklch(69.5% 0.221 282.5);
      --violet-9: oklch(31.7% 0.1845 282.5);
      --violet-10: oklch(38.9% 0.1845 282.5);
      --violet-11: oklch(47.7% 0.221 282.5);
      --violet-12: oklch(31.4% 0.1845 282.5);

      --violet-a1: color(display-p3 0.9373 0.9529 0.9961 / 0.5);
      --violet-a2: color(display-p3 0.9098 0.9255 1 / 0.667);
      --violet-a3: color(display-p3 0.8863 0.8941 1 / 0.834);
      --violet-a4: color(display-p3 0.8078 0.8235 1 / 0.75);
      --violet-a5: color(display-p3 0.7176 0.7373 1 / 0.667);
      --violet-a6: color(display-p3 0.5765 0.6 1 / 0.584);
      --violet-a7: color(display-p3 0.3412 0.3569 0.9961 / 0.5);
      --violet-a8: color(display-p3 0 0 1 / 0.334);
      --violet-a9: color(display-p3 0.1412 0 0.5059 / 0.972);
      --violet-a10: color(display-p3 0.0941 0 0.549 / 0.843);
      --violet-a11: color(display-p3 0.1137 0 0.7451 / 0.77);
      --violet-a12: color(display-p3 0.1451 0 0.5059 / 0.98);

      --violet-contrast: #fff;
      --violet-surface: color(display-p3 0.9216 0.9333 0.9922 / 0.8);
      --violet-indicator: oklch(31.7% 0.1845 282.5);
      --violet-track: oklch(31.7% 0.1845 282.5);
    }
  }
}

:root,
.light,
.light-theme {
  --gray-1: #f5f5f5;
  --gray-2: #f0f0f0;
  --gray-3: #e7e7e7;
  --gray-4: #dfdfdf;
  --gray-5: #d7d7d7;
  --gray-6: #cfcfcf;
  --gray-7: #c3c3c3;
  --gray-8: #b0b0b0;
  --gray-9: #818181;
  --gray-10: #777;
  --gray-11: #5a5a5a;
  --gray-12: #1f1f1f;

  --gray-a1: #deefff3b;
  --gray-a2: #116ecb0d;
  --gray-a3: #093e7316;
  --gray-a4: #062c501e;
  --gray-a5: #04213d26;
  --gray-a6: #021a302e;
  --gray-a7: #0213243a;
  --gray-a8: var(--border-primary);
  --gray-a9: #02070c7d;
  --gray-a10: #00060a87;
  --gray-a11: var(--text-primary);
  --gray-a12: #000102e0;

  --gray-contrast: #ffffff;
  --gray-surface: #ffffffcc;
  --gray-indicator: #818181;
  --gray-track: #818181;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {

    :root,
    .light,
    .light-theme {
      --gray-1: oklch(96.9% 0 none);
      --gray-2: oklch(95.6% 0 none);
      --gray-3: oklch(92.8% 0 none);
      --gray-4: oklch(90.3% 0 none);
      --gray-5: oklch(87.9% 0 none);
      --gray-6: oklch(85.3% 0 none);
      --gray-7: oklch(81.8% 0 none);
      --gray-8: oklch(75.8% 0 none);
      --gray-9: oklch(60.4% 0 none);
      --gray-10: oklch(56.9% 0 none);
      --gray-11: oklch(46.7% 0 none);
      --gray-12: oklch(24% 0 none);

      --gray-a1: color(display-p3 0.8471 0.9255 1 / 0.167);
      --gray-a2: color(display-p3 0.0078 0.3451 0.6863 / 0.044);
      --gray-a3: color(display-p3 0.0039 0.1843 0.3647 / 0.08);
      --gray-a4: color(display-p3 0.0039 0.1294 0.2549 / 0.112);
      --gray-a5: color(display-p3 0.0078 0.098 0.1922 / 0.144);
      --gray-a6: color(display-p3 0.0039 0.0745 0.1529 / 0.176);
      --gray-a7: color(display-p3 0.0039 0.0588 0.1137 / 0.224);
      --gray-a8: color(display-p3 0.0039 0.0392 0.0745 / 0.299);
      --gray-a9: color(display-p3 0.0039 0.0196 0.0353 / 0.487);
      --gray-a10: color(display-p3 0 0.0157 0.0314 / 0.526);
      --gray-a11: color(display-p3 0 0.0118 0.0196 / 0.642);
      --gray-a12: color(display-p3 0 0.0039 0.0039 / 0.877);

      --gray-contrast: #ffffff;
      --gray-surface: color(display-p3 1 1 1 / 80%);
      --gray-indicator: oklch(60.4% 0 none);
      --gray-track: oklch(60.4% 0 none);
    }
  }
}

:root,
.light,
.light-theme,
.radix-themes {
  --color-background: #fcf7f2;
}