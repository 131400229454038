.pulse {
  z-index: 1;
  background: transparent;
  border: 2.5px solid rgb(107, 54, 130);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-name: pulse-border;
}

@keyframes pulse-border {
  from {
    border-width: 2.5px;
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
  20% {
    opacity: 0.8;
    border-width: 2.5px;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    border-width: 0.5px;
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}
