.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: var(--space-2);
}
.truncable {
  align-self: flex-end;
  margin-top: 4;
}
.nav {
  display: flex;
  list-style-type: none;
}
.button {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 2;
  margin-right: 2;
}
.innerButton {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 2;
  margin-right: 2;
}
