@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");

.container {
  border-radius: 16px;
  position: relative;
  width: 500px;
  background-color: #000000;
}
.header {
  padding: 10px 16px 10px 36px;
}
.content {
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}
.content_header {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  padding: 12px 12px;
}
.content_header_inner {
  width: 100%;
}
.icon {
  border-radius: 50%;
  background-color: #727788;
  width: 36px;
  height: 36px;
}
.company_name {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
}
.sponsored {
  font-size: 13px;
  color: #ffffffa3;
}
.progress {
  position: relative;
  width: 100%;
}
.line {
  width: 100%;
  position: absolute;
  background-color: #ffffff;
  height: 6px;
  opacity: 0.4;
  border-radius: 8px;
}
.inner_line {
  position: absolute;
  opacity: 1;
  width: 30%;
  height: 6px;
  border-radius: 8px;
  background-color: #ffffff;
}
.time {
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
}
.shop_now {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 28px;
  border-radius: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  color: #0c6ec8;
  padding: 16px;
  letter-spacing: -2px;
}
.footer {
  width: 100%;
  padding: 16px 0px;
}
