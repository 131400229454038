.wrapper {
  background-color: #ece5e5;
  padding: 10px 24px;
  border-radius: 20px;
  width: min-content;
}
.wrapper-single {
  background-color: #ece5e5;
  padding: 5px;
  border-radius: 20px;
  width: min-content;
}
.wrapper-animation {
  animation: wrapper-scale 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing-single,
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--text-link);
  opacity: 0.4;
  color: #ff6b6b;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--text-link);
  opacity: 0.4;

  color: #ff6b6b;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--text-link);
  opacity: 0.4;

  color: #ff6b6b;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    opacity: 0.4;
  }
  50%,
  100% {
    opacity: 1;
  }
}

@keyframes wrapper-scale {
  0% {
    padding: 5px
  }
  100% {
    padding: 7px;
  }
}
