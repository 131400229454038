:root {
  /* Primary/Brand Colors */
  --primary-black: #000000;
  --primary-white: #ffffff;
  --primary-deep-purple: #2f048a;
  --primary-vibrant-orange: #ff4545;

  /* Secondary Colors */
  --secondary-light-green: #edf7b5;
  --secondary-beige: #fcf7f2;
  --secondary-orange: #ff621f;
  --secondary-pink: #dc3366;

  /* Text Colors */
  --text-primary: #121111;
  --text-secondary: #4c4747;
  --text-tertiary: #888282;
  --text-link: #8b54ff;
  --text-error: #D34840;

  /* UI Colors */
  --border-primary: #f0f0f0;
  --border-secondary: #f0f0f0;
  --border-transparent: #00000026;
  --border-transparent-light: #00000066;
  --icon-primary: #595d62;
  --icon-secondary: #707070;
  --icon-primary-active: #6933dc;

  /* Other Colors */
  --soft-button-background: rgb(47, 4, 138, 0.07);
  --background_light_grey: #f0edeb;
  --background_soft: #fcfbfa;
  --background_white: #ffffff;
  --background_light_grey_2: #edebf0;
  --background-pink-1: #ff9999;
  --border-strong: #9f9594;
  --border: var(--border-primary);
  --button-solid-dark-gradient: linear-gradient(97.73deg,
      #c55e5e -81.88%,
      #031012 90.53%);

  /* Margins/Paddings */
  --action-card-xb-padding: 48px;
  --action-card-compact-xb-padding: 32px;
  --editor-side-panel-left-padding: 70px;
  --editor-top-bar-height: 80px;

  /* z-indexes */
  --z-index-editor-side-bar: 1000;
  --z-index-editor-top-bar: 1001;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}