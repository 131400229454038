.dot {
  border-radius: 50%;
  border: 1px solid transparent;
  cursor: pointer;
  height: 18px;
  width: 18px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.dot:hover {
  border: 1px solid #eecbb1;
}
