.container {
  padding: 24px;
  border: 1px solid #ddd7d7;
  border-radius: 16px;
  background-color: white;
  width: 548px;
}
.header {
  padding: 16px 0px;
  width: 100%;
}
.footer1 {
  padding: 10px 0px;
  width: 100%;
}
.footer2 {
  margin-top: 6px;
  max-width: 450px;
}
.button {
  border-radius: 6px;
  padding: 8px 16px;
  background-color: #0766ff;
  color: white;
  font-weight: 500;
  font-size: 16px;
  border: none;
  height: fit-content;
}
.icon {
  border-radius: 50%;
  background-color: #727788;
  width: 36px;
  height: 36px;
}
.company_name {
  font-weight: 500;
  font-size: 16px;
}
.sponsored {
  font-size: 13px;
  color: #888282;
}
.interactive_button_text {
  color: #656565;
}
.primary_text {
  font-weight: 500;
  font-size: 16px;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shop_now {
  font-size: 16px;
  font-weight: 600;
}
.content {
  border-radius: 4px;
  border: 1px solid #ddd7d7;
}
.content_footer {
  padding: 12px 18px;
}
