.color-picker-content {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd7d7;
}

.color-picker-saturation {
  width: 100%;
  height: 150px;
  border-radius: 8px;
}

.react-colorful {
  width: 100% !important;
  height: auto !important;
}

.react-colorful__saturation-pointer {
  width: 8px !important;
  height: 8px !important;
}

.react-colorful__saturation {
  height: 200px !important;
  border-radius: 8px !important;
}

.react-colorful__hue {
  height: 16px !important;
  margin-top: 24px !important;
  border-radius: 8px !important;
}

.react-colorful__hue-pointer {
  width: 18px !important;
  height: 18px !important;
}

.react-colorful__hue-pointer > .react-colorful__pointer-fill {
  background-color: #ffffff !important;
}
