.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: fit-content;
  gap: 1rem;
  position: relative;
}

.loadContainer {
  width: 100px;
  height: 100px;
  align-items: center;
  align-self: flex-start;
  border-radius: var(--radius-3);
  border: 1px solid rgb(228, 228, 231);
}
.logoLoadContainer {
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  align-items: center;
  top: 0;
  left: 0;
}
